import { Component, inject, OnInit } from '@angular/core';
import {
  ResolveFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
  RouterLink,
} from '@angular/router';
import moment from 'moment';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ViewContentComponent } from 'src/app/components/_layout/view-content/view-content.component';
import { ViewHeaderComponent } from 'src/app/components/_layout/view-header/view-header.component';
import { OrderCardComponent } from 'src/app/components/order/order-card/order-card.component';
import { OrdersService } from 'src/app/services/orders.service';

export const purchaseOrdersListResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(OrdersService).getSentPurchaseOrdersList();
};

@Component({
  selector: 'app-purchase-orders-view',
  templateUrl: './purchase-orders.view.html',
  styleUrls: ['./purchase-orders.view.scss'],
  standalone: true,
  imports: [
    ViewHeaderComponent,
    ViewContentComponent,
    OrderCardComponent,
    NgxSkeletonLoaderModule,
    RouterLink,
  ],
})
export class PurchaseOrdersListComponent implements OnInit {
  public purchaseOrders = [];

  constructor(private _route: ActivatedRoute) {
    const { data } = this._route.snapshot.data;
    this.purchaseOrders = data.items;
  }

  ngOnInit() {}
}
