<!-- <view-header label="Tellimused"></view-header> -->

<!-- <view-content> -->
<!-- @defer { -->
<div class="grid grid-cols-1 flex-col gap-2x md:grid-cols-2 2xl:grid-cols-2">
  @for (order of purchaseOrders; track $index) {
  <order-card [data]="order"></order-card>
  }
</div>
<!-- } @placeholder (minimum 250ms) {
    <ngx-skeleton-loader
      class="grid grid-cols-1 flex-col gap-2x md:grid-cols-2 lg:grid-cols-2"
      [count]="orders.length"
      [theme]="{
        'border-radius': '5px',
        height: '9.25rem',
        border: '1px solid #efeeee',
      }"
    />
  } -->
<!-- </view-content> -->
